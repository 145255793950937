import { useSelector } from 'react-redux'
import _ from 'lodash'

import { getResults, getIsLoading, getCurrentDrug, getIsError, getIsWatching, getIsAllDrugs } from '../store/modules/drugs/selectors'

/**
 * @description returns drugs search results
 * @returns
 */
export const useDrugsResults = () => {
  return useSelector(getResults)
}


/**
 * @description returns if getting all drugs only
 * @returns
 */
export const useIsAllDrugs = () => {
  return useSelector(getIsAllDrugs)
}

/**
 * @description returns if the store is currently loading drugs
 * @returns
 */
export const useIsLoading = () => {
  return useSelector(getIsLoading)
}

/**
 * @description returns if the drug is in watchlist
 * @returns
 */
 export const useIsWatching = () => {
  return useSelector(getIsWatching)
}

/**
 * @description returns if the store is failed
 * @returns
 */
 export const useIsError = () => {
  return useSelector(getIsError)
}

/**
 * @description returns the current drug
 * @returns
 */
export const useCurrentDrug = () => {
  return useSelector(getCurrentDrug)
}

/**
 * @description Alphabetize drug list in alphabetical order
 * @returns
 */
export const sortedDrugs = (drugs) => drugs.sort((a, b) => a.name.localeCompare(b.name))

/**
 * @description returns drugs in group objects alphabetically by @nth letter
 * @returns
 */
 export const useDrugGroup = (drugs, nth) => {
  const data = drugs.reduce((accumulator, currentValue) => {
    let alphabet = currentValue.name[nth].toUpperCase();
    var punctuation = alphabet.match(/^[[.,:!?-]/)
    if (!_.isNaN(Number(alphabet)) || punctuation) {
      if (!accumulator['0-9']) accumulator['0-9'] = { alphabet: '0-9', record: [currentValue] }
      else accumulator['0-9'].record.push(currentValue);
    } else {
      if (!accumulator[alphabet]) accumulator[alphabet] = { alphabet, record: [currentValue] }
      else accumulator[alphabet].record.push(currentValue);
    }
    
    return accumulator;
  }, {});


  function moveNonAlphebatToLast(arr) {
    const [nonAlphebatArray, alphebatArray] = _.partition(arr, element => element.alphabet === '0-9')
    const sortedAlphebatArray = alphebatArray.slice().sort((a, b) => {
      return a.alphabet.localeCompare(b.alphabet)
    })

    return [...sortedAlphebatArray, ...nonAlphebatArray];
  }

  let dataArray = _.values(data)

  return moveNonAlphebatToLast(dataArray)
}
